import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { DefaultLayout } from "../layouts";
import { veryLightGrey, veryLightBlue, white } from "../utils";
import { Section, PaginationButtons, Background, Header } from "../components";

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  border: solid 1px rgb(89,89,89, 0.3);
  margin: 0 0 20px 0;
`;

const ArticleDate = styled.h2`
  background-color: rgb(89,89,89, 0.75);
  color: white;
  padding: 10px;
  font-size: 11pt;
  font-weight: 500;
  margin: 5px;
`;

const ArticleTitle = styled.h3`
  font-size: 10pt;
  margin: 15px;
`;

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const ArticleBox = ({ date, title, slug }) => (
  <ListItem>
    <StyledLink to={slug}>
      <ArticleDate>{date}</ArticleDate>
      <ArticleTitle>{title}</ArticleTitle>
    </StyledLink>
  </ListItem>
);

export default ({ pageContext, data }) => {
  const articles = data.articles.edges;

  return (
    <DefaultLayout title="The Daily Update">
      <Background image={data.logo.publicURL}>
        <Header color={white}>The Daily Update</Header>
      </Background>
      <Section heading="Latest News">
        <List>
          {articles.map((article, index) => (
            <ArticleBox key={index}
              date={article.node.frontmatter.date}
              title={article.node.frontmatter.title}
              slug={article.node.fields.slug}
            />
          ))}
        </List>
        <PaginationButtons pageContext={pageContext} relativePath="the-daily"/>
      </Section>
    </DefaultLayout>
  );
};

export const dailyUpdateQuery = graphql`
  query dailyUpdateQuery($skip: Int!, $limit: Int!) {
    articles: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(daily-updates)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
          }
        }
      }
    }
    logo: file(name: { eq: "news" }) {
      publicURL
    }
  }
`;
